import { biMap, match } from "@insightfulscience/shared-utils/either";
import { idX } from "@insightfulscience/shared-utils/fn";
import { not } from "@insightfulscience/shared-utils/validation";
import GET_PLAN_WITH_PRICES from "./queries/getPlanWithPrices.graphql";
import GET_RENEWABLE_SUBSCRIPTIONS from "./queries/getRenewableSubscriptions.graphql";
import GET_PERPETUAL from "./queries/getPerpetual.graphql";
import GET_SUBSCRIPTION_BY_ID from "./queries/getSubscriptionByID.graphql";
import GET_SUBSCRIPTION_BY_ID_WITH_PAYMENT_METHOD from "./queries/getSubscriptionByIDWithPaymentMethod.graphql";
import PURCHASE from "./mutations/purchase.graphql";
import ADD_ACTIVATION_TO_BLACK_LIST from "./mutations/addActivationToBlackList.graphql";
import REMOVE_ACTIVATION_FROM_BLACK_LIST from "./mutations/removeActivationFromBlackList.graphql";
import CANCEL_SUBSCRIPTION_BY_ID_V2 from "./mutations/cancelSubscriptionByIDV2.graphql";
import REACTIVATE_SUBSCRIPTION_BY_ID_V2 from "./mutations/reactivateSubscriptionByIDV2.graphql";
import GET_THREE_PLAN_PRICES from "./queries/getThreePlanPrices.graphql";
import GET_SUBSCRIPTION_WITH_ADDON_PRICES from "./queries/getSubscriptionWithAddonPrices.graphql";
import GET_SUBSCRIPTION_WITH_RENEW_PRICES from "./queries/getSubscriptionWithRenewPrices.graphql";
import UPDATE_SUBSCRIPTION_BY_ID_V2 from "./mutations/updateSubscriptionByIDV2.graphql";
import UPDATE_PAYMENT_METHOD_V2 from "./mutations/updatePaymentMethodV2.graphql";
import { gqlResultToEither } from "../../utils/gql";
import { mapPurchaseErrorCode } from "../../domain/purchase";
import { isIsul } from "../../domain/plan";

const ecommerceService = ({ gqlClient, storageService }) => {
	const getPrices = planCode =>
		gqlClient(GET_PLAN_WITH_PRICES, {
			planCode,
		})
			.then(data => data.getPlanWithPrices)
			.then(plan => plan && plan.prices);

	const getRenewableSubscriptions = () =>
		gqlClient(GET_RENEWABLE_SUBSCRIPTIONS)
			.then(data => data.getRenewableSubscriptions)
			.then(subscriptions => {
				const { planCode } = storageService.priceItem.read();
				const isIsulSubscription = ({ plan }) => isIsul(plan.planCode);
				return isIsul(planCode)
					? subscriptions.filter(isIsulSubscription)
					: subscriptions.filter(not(isIsulSubscription));
			});

	const getSubscriptionById = (id, subscriptionQuery = GET_SUBSCRIPTION_BY_ID) =>
		gqlClient(subscriptionQuery, { subscriptionID: parseInt(id, 10) }).then(
			data => data && data.getSubscriptionByID,
		);

	const getSubscriptionWithPlanPrices = (id, purchaseType) =>
		getSubscriptionById(
			id,
			purchaseType === "renew" // checking purchaseType
				? GET_SUBSCRIPTION_WITH_RENEW_PRICES
				: GET_SUBSCRIPTION_WITH_ADDON_PRICES,
		);

	const getSubscriptionWithPaymentMethod = id =>
		gqlClient(GET_SUBSCRIPTION_BY_ID_WITH_PAYMENT_METHOD, {
			subscriptionID: parseInt(id, 10),
		}).then(data => data.getSubscriptionByID);

	const getPerpetualById = id =>
		gqlClient(GET_PERPETUAL, { id: parseInt(id, 10) }).then(data => data.getPerpetual);

	const purchase = (cartAsJSON, token, paymentType) =>
		gqlClient(PURCHASE, { cartAsJSON, token, paymentType }, storageService.priceItem.read())
			.then(({ purchaseV2 }) => purchaseV2)
			.then(gqlResultToEither)
			.then(biMap(mapPurchaseErrorCode, idX));

	const addActivationToBlackList = (serialNumber, uuid) =>
		gqlClient(ADD_ACTIVATION_TO_BLACK_LIST, { serialNumber, uuid })
			.then(res => res.addActivationToBlacklistV2)
			.then(gqlResultToEither)
			.then(
				biMap(
					({ errorCode, message }) => ({ errorCode, message }),
					({ result }) => result,
				),
			)
			.then(
				match(e => {
					throw e;
				}, idX),
			);

	const removeActivationFromBlackList = (serialNumber, uuid) =>
		gqlClient(REMOVE_ACTIVATION_FROM_BLACK_LIST, { serialNumber, uuid })
			.then(res => res.removeActivationFromBlacklistV2)
			.then(gqlResultToEither)
			.then(
				biMap(
					({ errorCode, message }) => ({ errorCode, message }),
					({ result }) => result,
				),
			)
			.then(
				match(e => {
					throw e;
				}, idX),
			);

	const cancelSubscriptionWithSurvey = (subscriptionId, cancellationSurvey, planCode) =>
		gqlClient(CANCEL_SUBSCRIPTION_BY_ID_V2, {
			subscriptionId,
			planCode,
			cancellationSurvey,
		}).then(data => data.cancelSubscriptionByIDV2);

	const getThreePlanPrices = () => gqlClient(GET_THREE_PLAN_PRICES);

	const reactivateSubscription = ({ subscriptionID, planCode }) =>
		gqlClient(REACTIVATE_SUBSCRIPTION_BY_ID_V2, { subscriptionID, planCode }).then(
			data => data.reactivateSubscriptionByIDV2,
		);

	const updateSubscriptionById = (subscriptionID, planCode, newSeats) =>
		gqlClient(UPDATE_SUBSCRIPTION_BY_ID_V2, { subscriptionID, planCode, newSeats }).then(
			data => data.updateSubscriptionByIDV2,
		);

	const updatePaymentMethod = (subscriptionId, token, paymentType) =>
		gqlClient(UPDATE_PAYMENT_METHOD_V2, { subscriptionId, token }, { paymentType })
			.then(result => result.updatePaymentMethodV2)
			.then(gqlResultToEither)
			.then(biMap(mapPurchaseErrorCode, ({ result }) => result));

	return {
		getPrices,
		getRenewableSubscriptions,
		getSubscriptionById,
		getSubscriptionWithPaymentMethod,
		getPerpetualById,
		purchase,
		addActivationToBlackList,
		removeActivationFromBlackList,
		cancelSubscriptionWithSurvey,
		getThreePlanPrices,
		getSubscriptionWithPlanPrices,
		reactivateSubscription,
		updateSubscriptionById,
		updatePaymentMethod,
	};
};

export default ecommerceService;
