export const ERROR_STALE_CART = "STALE_CART";

export const FailedPaymentErrorCodes = ["EDECLINED_TRANSACTION", "EINVALID_TRANSACTION"];

export const isPaymentFailure = errorCode => FailedPaymentErrorCodes.includes(errorCode);

export const mapPurchaseErrorCode = ({ errorCode, message }) => ({
	errorCode: isPaymentFailure(errorCode) ? message || errorCode : errorCode,
	message,
});

export const mapRecurlyTokenError = ({ details }) =>
	details.map(({ field }) =>
		/^(?:first|last)_name$/.test(field)
			? "errors.RECURLY_NAME"
			: `errors.RECURLY_${field.toUpperCase()}`,
	);

export const mapRecurlyPaypalError = err => (!err ? [] : [err.message]);
