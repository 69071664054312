import { createGqlClient } from "@insightfulscience/shared-utils/gqlClient";
import {
	batch,
	context,
	recovery,
	tap,
} from "@insightfulscience/shared-utils/gqlClient/middlewares";

const gqlClient = ({
	storageService: { token },
	recoveryService: { shouldBeRecovered, recover },
	publicConfig: { GRAPHQL_URI },
	gqlEventsListener,
	apiMock,
}) =>
	createGqlClient(
		{ uri: GRAPHQL_URI },
		tap(gqlEventsListener),
		recovery(shouldBeRecovered, recover()),
		batch(),
		context(ctx => ({
			...ctx,
			headers: {
				...ctx.headers,
				Authorization: `Bearer ${token.read()}`,
				"x-mock": apiMock.read() || undefined,
			},
		})),
	);

export default gqlClient;
