import { Operation } from "@insightfulscience/shared-utils/gqlClient/operation";
import { isExpectedGqlError } from "@insightfulscience/shared-utils/gqlClient/GqlError";
import { isTokenValid } from "../../domain/token";
import REFRESH_ACCESS from "./refreshAccess.mutation.graphql";

const isUnauthorized = isExpectedGqlError(401);

const RecoveryService = ({ storageService: { refreshToken, setTokens, clearAll } }) => ({
	shouldBeRecovered: err => isUnauthorized(err) && isTokenValid(refreshToken.read()),
	recover: () => {
		let recoveringPromise = null;
		return (context, operation, gqlClient) => {
			recoveringPromise =
				recoveringPromise ||
				gqlClient
					.last(Operation(REFRESH_ACCESS, { refreshToken: refreshToken.read() }), context)
					.then(({ refreshAccess }) => {
						setTokens(refreshAccess);
						recoveringPromise = null;
					})
					.catch(err => {
						clearAll();
						return Promise.reject(err);
					});

			return recoveringPromise;
		};
	},
});

export default RecoveryService;
