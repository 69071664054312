import NextPageWithServices from "@insightfulscience/smart-react/hocs/NextPageWithServices";
import { publicConfig } from "../config";
import storage from "./storage";
import storageService from "./storageService";
import recoveryService from "./recoveryService";
import gqlClient from "./gqlClient";
import authService from "./authService";
import trialService from "./trialService";
import { token } from "./storageService/token";
import { refreshToken } from "./storageService/refreshToken";
import { priceItem } from "./storageService/priceItem";
import { billingInfo } from "./storageService/billingInfo";
import { cancellationSurvey } from "./storageService/cancellationSurvey";
import { trial } from "./storageService/trial";
import { apiMock } from "./storageService/apiMock";
import ecommerceService from "./ecommerceService";
import routingService from "./routingService";
import gqlEventsListener from "./GtmGqlEvents";

export const serviceCore = {
	publicConfig: () => publicConfig,
	storage,
	priceItem,
	billingInfo,
	cancellationSurvey,
	trial,
	token,
	refreshToken,
	storageService,
	recoveryService,
	gqlClient,
	authService,
	routingService,
	gqlEventsListener,
	trialService,
	ecommerceService,
	apiMock,
};

export default NextPageWithServices(serviceCore);
