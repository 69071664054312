import { not } from "@insightfulscience/shared-utils/validation";
import { isTokenValid } from "../../domain/token";

export const areTokensValid = (token, refreshToken) =>
	isTokenValid(token.read()) && isTokenValid(refreshToken.read());

export const isAuthorized = (context, { storageService: { token, refreshToken } }) =>
	areTokensValid(token, refreshToken);

export const isNotAuthorized = not(isAuthorized);
