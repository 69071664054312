import { Left, Right } from "@insightfulscience/shared-utils/either";

export const isGqlFailure = res => !!res?.errorCode;

export const gqlResultToEither = res => (isGqlFailure(res) ? Left(res) : Right(res));

/*
	We're not using mapGqlError from @insightfulscience/shared-utils,
	cause only errors with error messages are accepted as expected, the rest are throwing general exception
*/
export const globalGqlErrors = {
	412: "errors.TURING_TEST_FAILED",
	503: "errors.SERVICE_UNAVAILABLE",
};

const raise = e => {
	throw e;
};

export const errorToLeft = (gqlErrors, asArray = false) => err => {
	const newError =
		// prettier-ignore
		typeof err === "string" ? `errors.${err}` :
			err.code && err.code in gqlErrors ? gqlErrors[err.code] :
			raise(err);

	return Left(asArray ? [newError] : newError);
};
