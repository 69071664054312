import { biMap } from "@insightfulscience/shared-utils/either";
import { idX } from "@insightfulscience/shared-utils/fn";

import SIGN_IN from "./mutations/signIn.graphql";
import SIGN_UP from "./mutations/signUp.graphql";
import SIGN_OUT from "./mutations/signOut.graphql";
import FORGOT_PASSWORD from "./mutations/forgotPassword.graphql";
import RESET_PASSWORD from "./mutations/resetPassword.graphql";
import EXCHANGE_TOKEN from "./mutations/exchangeToken.graphql";
import { areTokensValid } from "./status";
import { gqlResultToEither } from "../../utils/gql";

const authService = ({
	gqlClient,
	storageService: { token, refreshToken, setTokens, clearAll },
}) => {
	/** @type {(email: string, password: string) => Promise<void>} */
	const signIn = (email, password) =>
		gqlClient(SIGN_IN, { email, password })
			.then(({ signInV2 }) => signInV2)
			.then(gqlResultToEither)
			.then(
				biMap(
					({ errorCode }) => errorCode,
					res => {
						setTokens(res);
						return res;
					},
				),
			);

	/** @type {(
	    firstName: string,
	    lastName: string,
	    company: string,
	    email: string,
	    password: string,
	    turingToken: string,
	    turingType: string,
	 * ) => Promise<void>} */
	const signUp = (firstName, lastName, company, email, password, turingToken, turingType) =>
		gqlClient(
			SIGN_UP,
			{
				firstName,
				lastName,
				email,
				password,
				company,
			},
			{ headers: { "x-turing-token": turingToken, "x-turing-type": turingType } },
		)
			.then(({ signUpV2 }) => signUpV2)
			.then(gqlResultToEither)
			.then(
				biMap(
					({ errorCode }) => errorCode,
					res => {
						setTokens(res);
						return res;
					},
				),
			);

	/** @type {() => Promise<true>} */
	const signOut = () => {
		const rToken = refreshToken.read();
		clearAll();
		if (rToken == null) return Promise.resolve(true);
		return gqlClient(SIGN_OUT, { refreshToken: rToken }).then(result => result.signOut);
	};

	/** @type {(email: string) => Promise<boolean>} */
	const forgotPassword = email =>
		gqlClient(FORGOT_PASSWORD, { email }).then(result => result.getCustomerForgotPasswordLink);

	/** @type {(passwordResetID: string, password: string) => Promise<boolean>} */
	const resetPassword = (passwordResetID, password) =>
		gqlClient(RESET_PASSWORD, { passwordResetID, password })
			.then(({ setCustomerPasswordByPasswordResetIDV2 }) => setCustomerPasswordByPasswordResetIDV2)
			.then(gqlResultToEither)
			.then(biMap(({ errorCode }) => errorCode, idX));

	const isAuthorized = () => areTokensValid(token, refreshToken);

	/** @type {(issuerId: string, idToken: string) => Promise<void>} */
	const exchangeIdToken = (issuerId, idToken) =>
		gqlClient(EXCHANGE_TOKEN, { issuerId, idToken }).then(
			result => setTokens(result.exchangeToken), //
		);

	return { signIn, signUp, signOut, forgotPassword, resetPassword, isAuthorized, exchangeIdToken };
};

export default authService;
